<template>
  <div class="serve">
    <div class="phone">
      <img src="../assets/image/icon/phone.png" @click="callPhone" />
    </div>
    <div><img src="../assets/image/icon/wechat.png" @click="f_close" /></div>
    <div>
      <img src="../assets/image/icon/home.png" @click="gohome" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import wx from "weixin-js-sdk";
export default {
  name: "HelloWorld",
  props: {},
  setup() {
    const router = useRouter();
    const callPhone = () => {
      window.location.href = "tel://18800085516";
    };
    const gohome = () => {
      router.push({ path: "/" });
    };
    const f_close = () => {
      if (typeof wx != "undefined") {
        wx.invoke('closeWindow',{},function(){});
      } else {
        if (navigator.userAgent.indexOf("MSIE") > 0) {
          if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
            window.opener = null;
            window.close();
          } else {
            window.open("", "_top");
            window.top.close();
          }
        } else if (navigator.userAgent.indexOf("Firefox") > 0) {
          window.location.href = "about:blank ";
        } else {
          window.opener = null;
          window.open("", "_self", "");
          window.close();
        }
      }
    };
    return {
      callPhone,
      gohome,
      f_close,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.serve {
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  flex-direction: column;
  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 2px 2px 10px #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    img {
      width: 60%;
    }
  }
  .phone {
    img {
      width: 50%;
    }
  }
}
</style>
